import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import Content from "../components/content"
import Product from "../components/product"
import Topic from "../components/topic"

const Positions = () => {
    return(
    <Layout>
        <Header page="positions"/>
        <Content>        
            <div className="m-8">
                <Topic>Artists</Topic>
                <div className="lg:m-8 w-full lg:text-xl">
                    <p className="font-bold lg:text-xl">Pixel Artist (Part Time, Remote)</p><br/>
                    <p>We are looking for a pixel artist to work on new Projects and Games.</p>
                    <ul className="list-disc pl-8">
                        <li>Comfortable creating Pixel Art of different styles</li>
                        <li>Familiar with Pixel Art tools like Aseprite</li>
                        <li>Able to commit 3 hours daily on weekdays</li>
                    </ul>
                    <p>Please send contact information and links to your portfolio to: <a className="text-nav" href="mailto:biz@bigbadrobots.com">biz -at- bigbadrobotscom</a> titled: PIXEL ARTIST</p>
                </div>
            </div>
        </Content>
        <Footer />
    </Layout>
    )
}

export default Positions;